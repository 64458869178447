export const ActionTypes = {
  VERIFY_STORE_SUCCESS: 'VERIFY_STORE_SUCCESS',
  VERIFY_STORE_FAILED: 'VERIFY_STORE_FAILED',
  SORT_TABLE: 'SORT_TABLE',
  GOT_ORDERS: 'GOT_ORDERS',
  CANT_GET_ORDERS: 'CANT_GET_ORDERS',
  ORDER_TABLE_NEXT_PAGE: 'ORDER_TABLE_NEXT_PAGE',
  ORDER_TABLE_PREVIOUS_PAGE: 'ORDER_TABLE_PREVIOUS_PAGE',
  GOT_PRINTERS: 'GOT_PRINTERS',
  CANT_GET_PRINTERS: 'CANT_GET_PRINTERS',
  STOP_PRINTING: 'STOP_PRINTING',
  START_PRINTING: 'START_PRINTING',
  SELECT_STORE: 'SELECT_STORE',
  ORDER_TABLE_TO_PAGE: 'ORDER_TABLE_TO_PAGE',
  UPDATE_PRINT_FILTER: 'UPDATE_PRINT_FILTER',
  PRINT_ORDER: 'PRINT_ORDER',
  GOT_INVENTORY: 'GOT_INVENTORY',
  CANT_GET_INVENTORY: 'CANT_GET_INVENTORY',
  CLOSE_INVENTORY_SECTION: 'CLOSE_INVENTORY_SECTION',
  OPEN_INVENTORY_SECTION: 'OPEN_INVENTORY_SECTION',
  UPDATE_INVENTORY_ITEM: 'UPDATE_INVENTORY_ITEM',
  ON_UPDATE_STORE_INVENTORY: 'ON_UPDATE_STORE_INVENTORY',
  UPDATE_STORE_INVENTORY_SUCCESS: 'UPDATE_STORE_INVENTORY_SUCCESS',
  UPDATE_STORE_INVENTORY_FAIL: 'UPDATE_STORE_INVENTORY_FAIL',
  RESET_INVENTORY_SAVE_BUTTON: 'RESET_INVENTORY_SAVE_BUTTON'
};
