class Pager {
  constructor(entriesPerPage = 0, dataLength = 0) {
    this.dataLength = dataLength;
    this.entriesPerPage = entriesPerPage;
    this.currentPage = 0;
    this.numberOfPages = Math.ceil(dataLength / entriesPerPage) || 0;
  }
  
  nextPage() {
    this.currentPage++;
  }
  
  previousPage() {
    this.currentPage--;
  }
  
  setCurrentPage(num) {
    this.currentPage = num;
  }
  
  resetPage() {
    this.currentPage = 0;
  }
  
  updateDataLength(length) {
    this.dataLength = length;
    this.numberOfPages = Math.ceil(length / this.entriesPerPage);
    if(this.currentPage + 1 > this.numberOfPages) {
      this.currentPage = this.numberOfPages - 1;
    }
  }
  
  getNumberOfPages() {
    return this.numberOfPages;
  }
  
  getDataLength() {
    return this.dataLength;
  }
  
  getCurrentPage() {
    return this.currentPage;
  }
  
  getCurrentPageBeginning() {
    return (this.currentPage * this.entriesPerPage);
  }
  
  getCurrentPageEnd() {
    return this.getCurrentPageBeginning() + this.getPageLimit();
  }
  
  getPageLimit() {
    let remainingEntries = this.dataLength - (this.getCurrentPage() * this.entriesPerPage);
    if( remainingEntries < this.entriesPerPage ) {
      return remainingEntries;
    } else {
      return this.entriesPerPage;
    }
  }
  
  isFirstPage() {
    return this.currentPage === 0;
  }
  
  isLastPage() {
    return this.currentPage + 1 === this.numberOfPages;
  }
}

export default Pager;