import { SERVER_URLS } from '../constants/storeConstants';
import { storeService } from '../services/storeSvc';

class InventoryService {
  constructor() {
    this.updateInventoryStatus = {
      PENDING: 0,
      LOADING: 1,
      SUCCESS: 2,
      ERROR:   3
    };
  }
  
  getInventory() {
    let shop = 'lovemesweet.myshopify.com';
    return new Promise((resolve, reject) => {
      $.getJSON(`${SERVER_URLS.inventory}`, {shop: shop})
        .done((data) => resolve(data))
        .fail((error) => reject(error));
    });
  }
  
  updateInventory(locationKey, unavailableProducts) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${SERVER_URLS.inventory}/${locationKey}`,
        type: 'PUT',
        data: JSON.stringify({ 'unavailableProducts': unavailableProducts }),
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + storeService.token
        }
      })
      .done(data => resolve(data))
      .fail(err => reject(err));
    });
  }
}

export let inventoryService = new InventoryService();