import appDispatcher from '../dispatcher/appDispatcher';
import { ActionTypes } from '../constants/actionTypes';
import { orderService } from '../services/orderSvc';

let OrderTableAction = {
  getOrders: (store) => {
    orderService.getOrders(store)
      .then(data => appDispatcher.dispatch(ActionTypes.GOT_ORDERS, data))
      .catch(err => appDispatcher.dispatch(ActionTypes.CANT_GET_ORDERS, err));
  },
  
  sortTable: args => appDispatcher.dispatch(ActionTypes.SORT_TABLE, args),
  
  nextPage: () => appDispatcher.dispatch(ActionTypes.ORDER_TABLE_NEXT_PAGE),
  
  previousPage: () => appDispatcher.dispatch(ActionTypes.ORDER_TABLE_PREVIOUS_PAGE),
  
  jumpToPage: pageNum => appDispatcher.dispatch(ActionTypes.ORDER_TABLE_TO_PAGE, pageNum)
};

export { 
  OrderTableAction
};