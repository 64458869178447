import React from 'react';
import ReactDOM from 'react-dom';
import Inventory from './components/Inventory';
import Printer from './components/Printer';
import Store from './components/Store';

ReactDOM.render(
  <div id="app-root">
    <Inventory />
    <Printer />
    <Store />
  </div>,
  document.getElementById('root')
);