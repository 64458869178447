import React from 'react';
import OrderRow from './OrderRow';
import OrderHeader from './OrderHeader';
import TablePagination from './TablePagination';
import Pager from '../services/pagerFactory';
import { OrderTableAction } from '../actions/orderTableAction';

const OrderTable = props => {
  
  const setRows = () => {
    let rows = [];
    for(let i=props.pager.getCurrentPageBeginning(); i<props.pager.getCurrentPageEnd(); ++i) {
      rows.push(<OrderRow key={i} order={props.orders[i]} />);
    }
    return rows;
  };
  
  const sort = sortOpt => OrderTableAction.sortTable(sortOpt);
  
  return (
    <div>
      <table id="order-table" className="table table-responsive table-hover">
        <thead>
          <tr>
            {
              props.columns.map((column, idx) => <OrderHeader key={idx} column={column} sort={sort}/>)
            }
          </tr>
        </thead>
        <tbody>
          { setRows() }
        </tbody>
      </table>
      <TablePagination pager={props.pager}/>
    </div>
  );
};

OrderTable.propTypes = {
  columns: React.PropTypes.array.isRequired,
  filter: React.PropTypes.shape({
    key: React.PropTypes.string.isRequired,
    value: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.number,
      React.PropTypes.instanceOf(Date),
      React.PropTypes.instanceOf(moment)
    ]).isRequired
  }),
  pager: React.PropTypes.instanceOf(Pager),
  orders: React.PropTypes.array.isRequired
};

export default OrderTable;