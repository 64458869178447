import { SERVER_URLS } from '../constants/storeConstants';
import { storeService } from './storeSvc';
import { orderService } from './orderSvc';

class PrinterService {
  constructor() {
    this.printRule;
  }
  
  _getPrintRule() {
    if(this.printRule) {
      return new Promise(resolve => resolve());
    } else {
      return new Promise(resolve => {
        $.ajax({
          type: 'GET',
          url: `${SERVER_URLS.print_rule}`,
          dataType: 'json',
          headers: {
            'Authorization': 'Bearer ' + storeService.token
          },
        })
        .done(data => {
          if(data.printRules && data.printRules.length > 0) {
            this.printRule = data.printRules[0];
          }
          resolve();
        });
      });
    }
  }
  
  _getOrderLabel(order, printRuleId) {
    return new Promise((resolve, reject) => {
      if(order.label) {
        resolve(dymo.label.framework.openLabelXml(order.label));
      } else {
        $.ajax({
          type: 'GET',
          url: `${SERVER_URLS.orders_to_print}?print_rule=${printRuleId}&ids=${order.id}`,
          dataType: 'json',
          headers: {
            'Authorization': 'Bearer ' + storeService.token
          },
        })
        .done(data => {
          if(data.orders.length > 0) {
            resolve(dymo.label.framework.openLabelXml(data.orders[0].formattedLabel));
          }
        })
        .catch(err => reject(err));
      }
    });
  }
  
  _printNewOrders(newOrders, store, printer) {
    if(newOrders.orders.length > 0) {
      for(let i=0; i<newOrders.orders.length; ++i) {
        if(newOrders.orders[i].store === store || !store) {
          let l = dymo.label.framework.openLabelXml(newOrders.orders[i].label);
          l.print(printer.name);
        }
      }
    }
  }
  
  printNewOrders(printer, store) {
    return new Promise((resolve, reject) => {
      this._getPrintRule()
        .then(() => orderService._getNewOrdersToPrint(this.printRule._id))
        .then(data => {
          this._printNewOrders(data, store, printer);
          resolve(data);
        })
        .catch(err => reject(err));
    });
  }
  
  getPrinters() {
    return new Promise((resolve, reject) => {
      let printers = dymo.label.framework.getPrinters();
      
      if(Array.isArray(printers) && printers.length > 0) {
        resolve(printers);
      } else {
        reject('No printers found');
      }
    });
  }
  
  printOrder(printer, order) {
    this._getPrintRule()
      .then(() => this._getOrderLabel(order, this.printRule._id))
      .then(label => label.print(printer.name))
      .catch(err => {});
  }
  
}

export let printerService = new PrinterService();