class TableService {
  constructor() {
    this.sortOpt = {key: 'date', name: 'Pick Up Time', sortOrder: 'desc'};
    this.columns = [
      {
        name: 'Order',
        key: 'orderNumber',
        sort: {
          allow: true,
          order: 'none'
        }
      },
      {
        name: 'Pick Up Time',
        key: 'date',
        sort: {
          allow: true,
          order: 'desc'
        }
      },
      {
        name: 'Store',
        key: 'store',
        sort: {
          allow: true,
          order: 'none'
        }
      },
      {
        name: 'Customer',
        key: 'customer',
        sort: {
          allow: true,
          order: 'none'
        }
      },
      {
        name: 'Total',
        key: 'total',
        sort: {
          allow: true,
          order: 'none'
        }
      },
      {
        name: 'Print',
        sort: {
          allow: false,
          order: 'none'
        }
      }
    ];
  }
  
  setSortBy(columns, data) {
    for(let column of columns) {
      column.sort.order = 'none';
      if(data.name && data.name === column.name && data.sortOrder) {
        column.sort.order = data.sortOrder;
      }
    }
    this.sortOpt = data;
    return columns;
  }
  
  sortTable(entries, data) {
    // Determine the attribute that the table should be sorted by
    let sortTableBy = Object.keys(entries[0]).find(key => key === data.key);
    // Sort table depending on the type of attribute and the sort order
    switch (typeof entries[0][sortTableBy]) {
      case 'string':
        if(data.sortOrder === 'asc') {
          entries.sort((a,b) => {
            if(!isNaN(parseFloat(a[sortTableBy])) && !isNaN(b[sortTableBy])) {
              return a[sortTableBy] - b[sortTableBy];
            } else {
              return b[sortTableBy].localeCompare(a[sortTableBy]);
            }
          });
        } else if(data.sortOrder === 'desc') {
          entries.sort((a,b) => {
            if(!isNaN(parseFloat(a[sortTableBy])) && !isNaN(b[sortTableBy])) {
              return b[sortTableBy] - a[sortTableBy];
            } else {
              return a[sortTableBy].localeCompare(b[sortTableBy]);
            }
          });
        }
        break;
      case 'number':
        if(data.sortOrder === 'asc') {
          entries.sort((a,b) => a[sortTableBy] - b[sortTableBy]);
        } else if(data.sortOrder === 'desc') {
          entries.sort((a,b) => b[sortTableBy] - a[sortTableBy]);
        }
        break;
      case 'object':
        // Check if attribute is a date
        if(
          Object.prototype.toString.call(entries[0][sortTableBy]) === "[object Date]" && 
          !isNaN(entries[0][sortTableBy].getTime())
        ) {
          if(data.sortOrder === 'asc') {
            entries.sort((a,b) => a[sortTableBy].getTime() - b[sortTableBy].getTime());
          } else if(data.sortOrder === 'desc') {
            entries.sort((a,b) => b[sortTableBy].getTime() - a[sortTableBy].getTime());
          }
        } else if(moment.isMoment(entries[0][sortTableBy])) {
          if(data.sortOrder === 'asc') {
            entries.sort((a,b) => a[sortTableBy].valueOf() - b[sortTableBy].valueOf());
          } else if(data.sortOrder === 'desc') {
            entries.sort((a,b) => b[sortTableBy].valueOf() - a[sortTableBy].valueOf());
          }
        }
        break;
      default:
        break;
    }
    return entries;
  }
  
  filterTable(entries, filter) {
    if(filter) {
      return entries.filter(entry => entry[filter.key] === filter.value);
    } else {
      return entries;
    }
  }
}

export let tableService = new TableService();