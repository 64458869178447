const SERVER_URL = 'https://lms.thousand.plus/printorder';
// const SERVER_URL = 'http://localhost:9456';

const SERVER_URLS = {
  orders: `${SERVER_URL}/orders`,
  orders_to_print: `${SERVER_URL}/orders/print`,
  auth_connect: `${SERVER_URL}/auth/connect`,
  product_filters: `${SERVER_URL}/products/filters`,
  print_rule: `${SERVER_URL}/print-rules`,
  print_template: `${SERVER_URL}/print-templates`,
  inventory: `${SERVER_URL}/inventory`
};

export {
  SERVER_URL, SERVER_URLS
};
