import React, { Component } from 'react';
import InventoryStore from '../stores/inventoryStore';
import StoreInventory from '../components/StoreInventory';
import { InventoryAction } from '../actions/inventoryAction';

class Inventory extends Component {
  constructor() {
    super();
    this.state = {
      isVerified: InventoryStore.get('isVerified'),
      inventory: InventoryStore.get('inventory'),
      notificationOnUpdate: InventoryStore.get('notificationOnUpdate')
    };
    
    this.fluxStoreId = InventoryStore.registerView(() => this.updateState());
    
    InventoryAction.getInventory();
  }
  
  
  componentWillUnmount() {
    InventoryStore.deregisterView(this.fluxStoreId);
  }
  
  updateState() {
    this.setState ({
      isVerified: InventoryStore.get('isVerified'),
      inventory: InventoryStore.get('inventory'),
      notificationOnUpdate: InventoryStore.get('notificationOnUpdate')
    });
  }
  
  updateStoreInventory(locationName) {
    let inventory = InventoryStore.get('inventory');
    let locationKey = Object.keys(inventory).find(key => inventory[key].name === locationName);
    let storeInventory = inventory[locationKey].inventory;
    let unavailableProducts = [];
    for(let i=0; i < storeInventory.length; ++i) {
      if(!storeInventory[i].available) {
        unavailableProducts.push(storeInventory[i].product_id);
      }
    }
    InventoryAction.updateStoreInventory(locationKey, unavailableProducts);
  }
  
  render() {
    let displayInventorySection = () => {
      return (
        <section id="inventory-section" className="ui-main-section">
          <h2 className="section-header-text">Inventory</h2>
          <div className="section-content">
            <ul id="inventory-list" className="row-list">
              { Object.keys(this.state.inventory).map((key, idx) => <StoreInventory key={idx} locationKey={idx} location={this.state.inventory[key]} updateInventory={this.updateStoreInventory} notify={this.state.notificationOnUpdate} />) }
            </ul>
          </div>
        </section>
      );
    };
    
    let displayVerificationError = () => {
      return (<section id="inventory-section" />);
    };
    
    if(!this.state.isVerified.verified) {
      return displayVerificationError();
    } else {
      return displayInventorySection();
    }
  }
}

export default Inventory;