import React from 'react';

const OrderHeader = props => {
  const showAsc = order => (order === 'asc') ? 'active' : 'inactive';
  
  const showDesc = order => (order === 'desc') ? 'active' : 'inactive';
  
  const sort = order => {
    props.sort({
      key: props.column.key,
      name: props.column.name,
      sortOrder: order
    });
  };
  
  return (
    <th>
      <div className="column-header">
        <div className="column-header-name">{props.column.name}</div>
        {
          (props.column.sort.allow) ? 
            <div className="column-sort-control">
              <div className="ctrl-btn" onClick={() => sort('asc')}>
                <span className={`glyphicon glyphicon-triangle-top ${showAsc(props.column.sort.order)}`} />
              </div>
              <div className="ctrl-btn" onClick={() => sort('desc')}>
                <span className={`glyphicon glyphicon-triangle-bottom ${showDesc(props.column.sort.order)}`} />
              </div>
            </div>
          : null
        }
      </div>
    </th>
  );
};

OrderHeader.propTypes = {
  column: React.PropTypes.shape({
    name: React.PropTypes.string.isRequired,
    sort: React.PropTypes.shape({
      allow: React.PropTypes.bool.isRequired,
      order: React.PropTypes.oneOf(['none', 'asc', 'desc'])
    }).isRequired
  }).isRequired,
  sort: React.PropTypes.func.isRequired
};

export default OrderHeader;