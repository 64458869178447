import React from 'react';

const StoreFilter = props => {
  
  const displayStoreName = store => {
    let displayName = '';
    switch (store) {
      case 'stc': {
        displayName = 'STC';
        break;
      }
      case 'markham': {
        displayName = 'Markham';
        break;
      }
      default: {
        displayName = 'All Stores';
        break;
      }
    }
    return displayName;
  };
  
  return (
    <div id="store-filter">
      <div className="dropdown">
        <button className="btn btn-default dropdown-toggle" type="button" id="storeDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <span className="glyphicon glyphicon-filter dropdown-btn-icon"/>
          <span className="dropdown-btn-text">
            {displayStoreName(props.store)}</span>
          <span className="caret" />
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li key="0" onClick={() => props.selectStore('')}><a>All Stores</a></li>
          <li key="1" onClick={() => props.selectStore('markham')}><a>Markham</a></li>
          <li key="2" onClick={() => props.selectStore('stc')}><a>STC</a></li>
        </ul>
      </div>
    </div>
  );
};

StoreFilter.propTypes = {
  store: React.PropTypes.string.isRequired,
  selectStore: React.PropTypes.func.isRequired
};

export default StoreFilter;