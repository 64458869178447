'use strict';

import React from 'react';

const InventoryItem = ({locationKey, item, toggleItem}) => {
  const toggleAvailable = () => toggleItem(item);
  
  return (
    <li>
      <div className="checkbox-container">
        <input 
          id={`${locationKey}-${item.product_id}`} 
          className="tpx-checkbox" 
          type="checkbox"
          checked={item.available}
          onChange={() => toggleAvailable()}
        />
        <label htmlFor={`${locationKey}-${item.product_id}`}>{item.title}</label>
      </div>
    </li>
  );
};

InventoryItem.propTypes = {
  locationKey: React.PropTypes.number.isRequired,
  item: React.PropTypes.shape({
    product_id: React.PropTypes.number.isRequired,
    title: React.PropTypes.string.isRequired,
    available: React.PropTypes.bool.isRequired
  }).isRequired,
  toggleItem: React.PropTypes.func.isRequired
};

export default InventoryItem;