import React from 'react';

const Verify = props => {
  const isVerified = error => {
    let component;
    
    if(!error) {
      component = (
        <div>
          <h2 className="verifying-text">Verifying with Shopify. Please wait.</h2>
          <div className="spinning-wheel">
            <img src="./images/rolling.svg" alt="loading..."/>
          </div>
        </div>
      );
    } else {
      component = (<h2 className="verification-error">Unable to verify with Shopify. Please try again later.</h2>);
    }
    
    return component;
  };
  
  return (
    <div id="verification-screen">
      { isVerified(props.error) }
    </div>
  );
};

Verify.propTypes = {
  error: React.PropTypes.object
};

export default Verify;