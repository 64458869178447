import { SERVER_URLS } from '../constants/storeConstants';

class StoreService {
  constructor() {
    this.token;
  }
  
  verifyStore() {
    let _self = this;
    return new Promise((resolve, reject) => {
      let queryString = window.location.search;

      // TEST
//       let queryString = '?hmac=cec8269033e152b3409bc8c6be854fcaf8477aa2d81e8a4fb19f9ab0f9cff9bc&protocol=https%3A%2F%2F&shop=lovemesweet.myshopify.com&timestamp=1485556414';
      

      $.get(`${SERVER_URLS.auth_connect}${queryString}`)
        .done((data) => {
          if(!data.token) {
            reject('Data is corrupted');
          }
          // TODO: Use session and expire date on token once that exists
          Cookies.set('token', data.token, {expires: 7});
          _self.token = data.token;
          resolve();
        })
        .fail((error) => reject(error));
    });
  }
  
  getToken() {
    return this.token;
  }
  
}

export let storeService = new StoreService();