import Store from '../lib/Store';
import appDispatcher from '../dispatcher/appDispatcher';
import { ActionTypes } from '../constants/actionTypes';
import { inventoryService } from '../services/inventorySvc';
import { InventoryAction } from '../actions/inventoryAction';

class InventoryStore extends Store {
  constructor() {
    super('InventoryStore');
    this.initialize('isVerified', {verified: false});
    this.initialize('inventory', {});
    this.initialize('notificationOnUpdate', inventoryService.updateInventoryStatus.PENDING);
  }
  
  onAction(actionType, data) {
    switch(actionType) {
      case ActionTypes.VERIFY_STORE_SUCCESS: {
        this.set('isVerified', {verified: true});
        break;
      }
      case ActionTypes.VERIFY_STORE_FAILED: {
        this.set('isVerified', {verified: false, error: data});
        break;
      }
      case ActionTypes.GOT_INVENTORY: {
        for(let locationKey in data) {
          data[locationKey].openSection = false;
        }
        this.set('inventory', data);
        break;
      }
      case ActionTypes.CANT_GET_INVENTORY: {
        this.logger.error(data);
        break;
      }
      case ActionTypes.CLOSE_INVENTORY_SECTION: {
        let inventory = this.get('inventory');
        let matchingLocationKey = Object.keys(inventory).find(key => inventory[key].name === data);
        inventory[matchingLocationKey].openSection = false;
        this.changeStore();
        break;
      }
      case ActionTypes.OPEN_INVENTORY_SECTION: {
        let inventory = this.get('inventory');
        let matchingLocationKey = Object.keys(inventory).find(key => inventory[key].name === data);
        inventory[matchingLocationKey].openSection = true;
        this.changeStore();
        break;
      }
      case ActionTypes.UPDATE_INVENTORY_ITEM: {
        let locationName = data.location.name;
        let itemId = data.item.product_id;
        let inventory = this.get('inventory');
        let locationKey = Object.keys(inventory).find(key => inventory[key].name === locationName);
        if(locationKey) {
          let inventoryItem = inventory[locationKey].inventory.find(item => item.product_id === itemId);
          inventoryItem.available = !inventoryItem.available;
        }
        this.changeStore();
        break;
      }
      case ActionTypes.ON_UPDATE_STORE_INVENTORY: {
        this.set('notificationOnUpdate', inventoryService.updateInventoryStatus.LOADING);
        break;
      }
      case ActionTypes.UPDATE_STORE_INVENTORY_SUCCESS: {
        this.set('notificationOnUpdate', inventoryService.updateInventoryStatus.SUCCESS);
        break;
      }
      case ActionTypes.UPDATE_STORE_INVENTORY_FAIL: {
        this.set('notificationOnUpdate', inventoryService.updateInventoryStatus.ERROR);
        InventoryAction.getInventory();
        break;
      }
      case ActionTypes.RESET_INVENTORY_SAVE_BUTTON: {
        this.set('notificationOnUpdate', inventoryService.updateInventoryStatus.PENDING);
        break;
      }
      default: {
        this.logger.debug('Unknown action type for InventoryStore', actionType);
        break;
      }
    }
  }
  
}

let inventoryStore = new InventoryStore();
appDispatcher.registerStore(inventoryStore);

export default inventoryStore;