import Store from '../lib/Store';
import appDispatcher from '../dispatcher/appDispatcher';
import { ActionTypes } from '../constants/actionTypes';
import { PrinterAction } from '../actions/printerAction';
import { printerService } from '../services/printerSvc';
 
class PrinterStore extends Store {
  constructor() {
    super('PrinterStore');
    this.initialize('isVerified', {verified: false});
    this.initialize('printers', []);
    this.initialize('selectedPrinter', {});
    this.initialize('isPrinting', false);
    this.initialize('store', '');
  }
  
  onAction(actionType, data) {
    switch(actionType) {
      case ActionTypes.VERIFY_STORE_SUCCESS: {
        this.set('isVerified', {verified: true});
        break;
      }
      case ActionTypes.VERIFY_STORE_FAILED: {
        this.set('isVerified', {verified: false, error: data});
        break;
      }
      case ActionTypes.GOT_PRINTERS: {
        this.set('printers', data);
        if(data.length > 0) this.set('selectedPrinter', data[0]);
        break;
      }
      case ActionTypes.CANT_GET_PRINTERS: {
        this.set('printers', []);
        this.set('selectedPrinter', {});
        break;
      }
      case ActionTypes.START_PRINTING: {
        this.set('isPrinting', true);
        break;
      }
      case ActionTypes.STOP_PRINTING: {
        this.set('isPrinting', false);
        break;
      }
      case ActionTypes.SELECT_STORE: {
        this.set('store', data);
        if(this.get('isPrinting')) {
          PrinterAction.updateStore({printer: this.get('selectedPrinter'), store: data});
        }
        break;
      }
      case ActionTypes.PRINT_ORDER: {
        printerService.printOrder(this.get('selectedPrinter'), data);
        break;
      }
      default: {
//        this.logger.debug('Unknown action type for PrinterStore', actionType);
        break;
      }
    }
  }
}

let printerStore = new PrinterStore();
appDispatcher.registerStore(printerStore);

export default printerStore;