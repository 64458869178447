import appDispatcher from '../dispatcher/appDispatcher';
import { ActionTypes } from '../constants/actionTypes';
import { inventoryService } from '../services/inventorySvc';

let InventoryAction = {
  getInventory: () => {
    inventoryService.getInventory()
      .then(inventory => appDispatcher.dispatch(ActionTypes.GOT_INVENTORY, inventory))
      .catch(err => appDispatcher.dispatch(ActionTypes.CANT_GET_INVENTORY, err));
  },
  
  closeSection: locationTitle => appDispatcher.dispatch(ActionTypes.CLOSE_INVENTORY_SECTION, locationTitle),
  
  openSection: locationTitle => appDispatcher.dispatch(ActionTypes.OPEN_INVENTORY_SECTION, locationTitle),
  
  updateItemAvailability: (location, item) => 
    appDispatcher.dispatch(ActionTypes.UPDATE_INVENTORY_ITEM, {location: location, item: item}),
  
  updateStoreInventory: (locationKey, unavailableProducts) => {
    appDispatcher.dispatch(ActionTypes.ON_UPDATE_STORE_INVENTORY);
    inventoryService.updateInventory(locationKey, unavailableProducts)
      .then(data => appDispatcher.dispatch(ActionTypes.UPDATE_STORE_INVENTORY_SUCCESS, data))
      .catch(err => appDispatcher.dispatch(ActionTypes.UPDATE_STORE_INVENTORY_FAIL, err));
  },
  
  setSaveButtonToPending: () => appDispatcher.dispatch(ActionTypes.RESET_INVENTORY_SAVE_BUTTON)
};

export {
  InventoryAction
};