import React, { Component } from 'react';
import Verify from './Verify';
import OrderTable from './OrderTable';
import StoreStore from '../stores/storeStore';
import { StoreAction } from '../actions/storeAction';

class Store extends Component {
  constructor() {
    super();
    this.state = {
      isVerified: StoreStore.get('isVerified'),
      tableColumns: StoreStore.get('tableColumns'),
      filter: StoreStore.get('filter'),
      filteredOrders: StoreStore.get('filteredOrders'),
      orderTablePager: StoreStore.get('orderTablePager')
    };
    this.fluxStoreId = StoreStore.registerView(() => {
      this.updateState();
    });
    StoreAction.verifyStore();
  }
  
  componentWillUnmount() {
    clearInterval(this.state.getOrdersTimer);
    StoreStore.deregisterView(this.fluxStoreId);
  }
  
  updateState() {
    this.setState ({
      isVerified: StoreStore.get('isVerified'),
      tableColumns: StoreStore.get('tableColumns'),
      filter: StoreStore.get('filter'),
      filteredOrders: StoreStore.get('filteredOrders'),
      orderTablePager: StoreStore.get('orderTablePager')
    });
  }
  
  render() {
    let displayStore = isVerified => {
      if(isVerified.verified) {
        return (
          <div>
            <h2 className="section-header-text">Order Table</h2>
            <OrderTable 
              columns={this.state.tableColumns} 
              orders={this.state.filteredOrders} 
              filter={this.state.filter} 
              pager={this.state.orderTablePager} 
            />
          </div>
        );
      } else {
        return <Verify error={this.state.isVerified.error}/>;
      }
    };
    
    return (
      <div id="store" className="ui-main-section">
        {displayStore(this.state.isVerified)}
      </div>
    );
  }
}

export default Store;