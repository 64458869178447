import React, { Component } from 'react';
import PrinterStore from '../stores/printerStore';
import { PrinterAction } from '../actions/printerAction';
import TogglePrinting from './TogglePrinting';
import StoreFilter from './StoreFilter';

class Printer extends Component {
  constructor() {
    super();
    this.state = {
      isVerified: PrinterStore.get('isVerified'),
      printers: PrinterStore.get('printers'),
      selectedPrinter: PrinterStore.get('selectedPrinter'),
      isPrinting: PrinterStore.get('isPrinting'),
      store: PrinterStore.get('store')
    };
    this.fluxStoreId = PrinterStore.registerView(() => {
      this.updateState();
    });
    PrinterAction.getPrinters();
  }
  
  componentWillUnmount() {
    PrinterStore.deregisterView(this.fluxStoreId);
  }
  
  updateState() {
    this.setState({
      isVerified: PrinterStore.get('isVerified'),
      printers: PrinterStore.get('printers'),
      selectedPrinter: PrinterStore.get('selectedPrinter'),
      isPrinting: PrinterStore.get('isPrinting'),
      store: PrinterStore.get('store')
    });
  }
  
  togglePrinting(isPrinting) {
    PrinterAction.togglePrinting(PrinterStore.get('selectedPrinter'), PrinterStore.get('store'), isPrinting);
  }
  
  render() {
    let displayPrinterSection = () => {
      return (
        <section id="printer-section" className="ui-main-section">
          <h2 className="section-header-text">Printer</h2>
          <div className="section-content">
            <div id="printer-selector">
              <div className="dropdown">
                <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <span className="glyphicon glyphicon-print dropdown-btn-icon"/>
                  <span className="dropdown-btn-text">{this.state.selectedPrinter.name}</span>
                  <span className="caret" />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                  { 
                    this.state.printers.map((printer, idx) => <li key={idx}><a>{printer.name}</a></li>)
                  }
                </ul>
              </div>
            </div>
            <div id="printer-ctrl">
              <StoreFilter 
                store={this.state.store}
                selectStore={PrinterAction.selectStore}
              />
              <TogglePrinting 
                printer={this.state.selectedPrinter}
                isPrinting={this.state.isPrinting}
                toggle={this.togglePrinting}
              />
            </div>
          </div>
        </section>
      );
    };
    
    let displayNoPrinterFound = () => {
      return (
        <section id="priner-section" className="ui-main-section">
          <h2 className="section-header-text">Printer</h2>
          <p>No printer found!</p>
        </section>
      );
    };
    
    let displayVerifiedError = () => {
      return (<section id="priner-section" />);
    };
    
    if(!this.state.isVerified.verified) {
      return displayVerifiedError();
    } else if(Array.isArray(this.state.printers) && this.state.printers.length > 0) {
      return displayPrinterSection();
    } else {
      return displayNoPrinterFound();
    }
  }
}

export default Printer;