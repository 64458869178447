import appDispatcher from '../dispatcher/appDispatcher';
import Store from '../lib/Store';
import { ActionTypes } from '../constants/actionTypes';
import Pager from '../services/pagerFactory';
import { tableService } from '../services/tableSvc';
import { OrderTableAction } from '../actions/orderTableAction';
import { PrinterAction } from '../actions/printerAction';

class StoreStore extends Store {
  constructor() {
    super('StoreStore');
    this.initialize('isVerified', {verified: false});
    this.initialize('orders', []);
    this.initialize('filter', undefined);
    this.initialize('filteredOrders', []);
    this.initialize('orderTablePager', new Pager(12));
    this.initialize('tableColumns', tableService.columns);
    this.initialize('getOrdersTimer', undefined);
  }
  
  onAction(actionType, data) {
    switch(actionType) {
      case ActionTypes.VERIFY_STORE_SUCCESS: {
        this.set('isVerified', {verified: true});
        OrderTableAction.getOrders();
        this.set('getOrdersTimer', setInterval(OrderTableAction.getOrders, 30000));
        break;
      }
      case ActionTypes.VERIFY_STORE_FAILED: {
        this.set('isVerified', {verified: false, error: data});
        break;
      }
      case ActionTypes.GOT_ORDERS: {
        if(data.orders.length > 0) {
          let filteredOrders = tableService.filterTable(data.orders, this.get('filter'));
          // Save orders to record
          for(let i=0; i<data.orders.length; ++i) {
            this.get('orders').push(data.orders[i]);
          }
          // Filter orders
          filteredOrders.forEach(order => this.get('filteredOrders').push(order));
          this.get('orderTablePager').updateDataLength(this.get('filteredOrders').length);
          // Sort orders
          tableService.setSortBy(this.get('tableColumns'), tableService.sortOpt);
          tableService.sortTable(this.get('filteredOrders'), tableService.sortOpt);
          // Update view
          this.changeStore();
        }
        break;
      }
      case ActionTypes.CANT_GET_ORDERS: {
        this.logger.debug("Can't get orders");
        break;
      }
      case ActionTypes.SORT_TABLE: {
        tableService.setSortBy(this.get('tableColumns'), data);
        tableService.sortTable(this.get('filteredOrders'), data);
        this.get('orderTablePager').resetPage();
        this.changeStore();
        break;
      }
      case ActionTypes.ORDER_TABLE_NEXT_PAGE: {
        this.get('orderTablePager').nextPage();
        this.changeStore();
        break;
      }
      case ActionTypes.ORDER_TABLE_PREVIOUS_PAGE: {
        this.get('orderTablePager').previousPage();
        this.changeStore();
        break;
      }
      case ActionTypes.ORDER_TABLE_TO_PAGE: {
        this.get('orderTablePager').setCurrentPage(data);
        this.changeStore();
        break;
      }
      case ActionTypes.START_PRINTING: {
        let getOrdersTimer = this.get('getOrdersTimer');
        if(getOrdersTimer) {
          clearInterval(this.get('getOrdersTimer'));
        }
        PrinterAction.printOrders(data.printer, data.store);
        this.set('getOrdersTimer', setInterval(() => {
          PrinterAction.printOrders(data.printer, data.store);
        }, 30000));
        break;
      }
      case ActionTypes.STOP_PRINTING: {
        let getOrdersTimer = this.get('getOrdersTimer');
        if(getOrdersTimer) {
          clearInterval(this.get('getOrdersTimer'));
        }
        this.set('getOrdersTimer', setInterval(OrderTableAction.getOrders, 30000));
        break;
      }
      case ActionTypes.SELECT_STORE: {
        let filter = (data) ? {key: 'store', value: data} : undefined;
        let filteredOrders = tableService.filterTable(this.get('orders'), filter);
        this.set('filter', filter);
        this.get('orderTablePager').updateDataLength(filteredOrders.length);
        this.set('filteredOrders', filteredOrders);
        break;
      }
      case ActionTypes.UPDATE_PRINT_FILTER: {
        clearInterval(this.get('getOrdersTimer'));
        this.set('getOrdersTimer', setInterval(() =>{
          PrinterAction.printOrders(data.printer, data.store);
        }, 30000));
        break;
      }
      default:
//        this.logger.debug('Unknown action type for StoreStore');
        break;
    }
  }
}

let storeStore = new StoreStore();
appDispatcher.registerStore(storeStore);

export default storeStore;