import { SERVER_URLS } from '../constants/storeConstants';
import { storeService } from './storeSvc';
import Order from './orderFactory';
import { storeStore } from '../stores/storeStore';

class OrderService {
  constructor() {
    this.getOrdersTimer = undefined;
    this.sinceId = undefined;
  }
  
  _getNewOrdersToPrint(printRuleId, store) {
    let createdAtMin = moment('1970-01-01').format();
    let constructQueryString = (printRuleId, store, sinceId) => {
      let storeQuery = (store) ? `store=${store}&` : '';
      let timeQuery = (sinceId) ? `since_id=${sinceId}` : `created_at_min=${createdAtMin}`;
      return "?".concat(`print_rule=${printRuleId}&`).concat(storeQuery).concat(timeQuery);
    };
    return new Promise((resolve, reject) => {

      $.ajax({
        type: 'GET',
        url: `${SERVER_URLS.orders_to_print}${constructQueryString(printRuleId, store, this.sinceId)}`,
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + storeService.token
        },
      })
      .done(data => {
        let newOrders = [];
        for(let i=0; i<data.orders.length; ++i) {
          newOrders.push(new Order(data.orders[i]));
        }
        data.orders = newOrders;
        this.sinceId = data.since_id;
        resolve(data);
      })
      .fail(error => reject(error));
    });
  }
  
  getOrders(store) {
    let createdAtMin = moment('1970-01-01').format();
    let constructQueryString = (store, sinceId) => {
      let storeQuery = (store) ? `store=${store}&` : '';
      let timeQuery = (sinceId) ? `since_id=${sinceId}` : `created_at_min=${createdAtMin}`;
      return "?".concat(storeQuery).concat(timeQuery);
    };
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: `${SERVER_URLS.orders}${constructQueryString(store, this.sinceId)}`,
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + storeService.token
        },
      })
      .done(data => {
        let newOrders = [];
        for(let i=0; i<data.orders.length; ++i) {
          newOrders.push(new Order(data.orders[i]));
        }
        data.orders = newOrders;
        this.sinceId = data.since_id;
        resolve(data);
      })
      .fail(error => reject(error));
    });
  }
  
  sortOrders(data) {
    // Get a handle of the orders. storeStore should be the only place where you can get the orders (Single source of truth!)
    let orders = storeStore.get('orders');
    // Determine the attribute that the table should be sorted by
    let sortTableBy = Object.keys(orders[0])[data.index];
    // Sort table depending on the type of attribute and the sort order
    switch (typeof orders[0][sortTableBy]) {
      case 'string':
        if(data.sortOrder === 'asc') {
          orders.sort((a,b) => {
            return a[sortTableBy].localeCompare(b[sortTableBy]);
          });
        } else if(data.sortOrder === 'desc') {
          orders.sort((a,b) => {
            return b[sortTableBy].localeCompare(a[sortTableBy]);
          });
        }
        break;
      case 'number':
        if(data.sortOrder === 'asc') {
          orders.sort((a,b) => {
            return a[sortTableBy] - b[sortTableBy];
          });
        } else if(data.sortOrder === 'desc') {
          orders.sort((a,b) => {
            return b[sortTableBy] - a[sortTableBy];
          });
        }
        break;
      case 'object':
        // Check if attribute is a date
        if(
          Object.prototype.toString.call(orders[0][sortTableBy]) === "[object Date]" && 
          !isNaN(orders[0][sortTableBy].getTime())
        ) {
          if(data.sortOrder === 'asc') {
            orders.sort((a,b) => {
              return a[sortTableBy].getTime() - b[sortTableBy].getTime();
            });
          } else if(data.sortOrder === 'desc') {
            orders.sort((a,b) => {
              return b[sortTableBy].getTime() - a[sortTableBy].getTime();
            });
          }
        } else if(moment.isMoment(orders[0][sortTableBy])) {
          if(data.sortOrder === 'asc') {
            orders.sort((a,b) => {
              return a[sortTableBy].valueOf() - b[sortTableBy].valueOf();
            });
          } else if(data.sortOrder === 'desc') {
            orders.sort((a,b) => {
              return b[sortTableBy].valueOf() - a[sortTableBy].valueOf();
            });
          }
        }
        break;
      default:
        break;
    }
  }
}

export let orderService = new OrderService();