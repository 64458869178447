class Order {
  constructor(o) {
    this.id = o.id;
    this.orderNumber = o.order_number;
    this.date = this._getPickupDatetime(o);
    this.formattedPickupDate = (this.date) ? this.date.format('MMM DD YYYY, h:mm a') : undefined;
    this.customerId = o.customer.id;
    this.customer = `${o.customer.first_name} ${o.customer.last_name}`;
    this.total = parseFloat(o.total_price).toFixed(2);
    this.label = o.formattedLabel;
    this.store = this._getPickupLocation(o);
  }
  
  _getPickupDatetime(order) {
    let pickupDateTimeAttr = order.note_attributes.find(attr => attr.name === 'pickup-datetime');
    return (pickupDateTimeAttr) ? moment(pickupDateTimeAttr.value) : 0; 
  }
  
  _getPickupLocation(order) {
    let pickupLocationAttr = order.note_attributes.find(attr => attr.name === 'store');
    return (pickupLocationAttr) ? pickupLocationAttr.value : '';
  }
}

export default Order;