import React from 'react';
import { PrinterAction } from '../actions/printerAction';

const OrderRow = props => {
  
  const print = order => PrinterAction.printOrder(order);
  
  return (
    <tr>
      <td>
        <a href={`https://lovemesweet.myshopify.com/admin/orders/${props.order.id}`} target="_blank">
          #{props.order.orderNumber}
        </a>
      </td>
      <td>{props.order.formattedPickupDate}</td>
      <td>{props.order.store}</td>
      <td>
        <a href={`https://lovemesweet.myshopify.com/admin/customers/${props.order.customerId}`} target="_blank">
          {props.order.customer}
        </a>
      </td>
      <td>${props.order.total}</td>
      <td>
        <button type="button" className="btn btn-block btn-default action-btn" onClick={() => print(props.order)}>
          <span className="glyphicon glyphicon-print" />
        </button>
      </td>
    </tr>
  );
};

OrderRow.propTypes = {
  order: React.PropTypes.shape({
    id: React.PropTypes.number.isRequired,
    orderNumber: React.PropTypes.number.isRequired,
    formattedPickupDate: React.PropTypes.string,
    store: React.PropTypes.string,
    customerId: React.PropTypes.number.isRequired,
    customer: React.PropTypes.string.isRequired,
    total: React.PropTypes.string.isRequired
  }).isRequired
};

export default OrderRow;