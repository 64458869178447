import React from 'react';
import { OrderTableAction } from '../actions/orderTableAction';

const TablePagination = props => {
  
  const isNextDisabled = () => (props.pager.isLastPage()) ? 'disabled' : '';
  
  const isPreviousDisabled = () => (props.pager.isFirstPage()) ? 'disabled' : '';
  
  const isActive = pageNumber => (pageNumber === props.pager.getCurrentPage()) ? 'active' : '';
  
  const nextPage = () => {
    if(!isNextDisabled()) {
      OrderTableAction.nextPage();
    }
  };
  
  const previousPage = () => {
    if(!isPreviousDisabled()) {
      OrderTableAction.previousPage();
    }
  };
  
  const jumpToPage = pageNum => OrderTableAction.jumpToPage(pageNum);
  
  const setPages = () => {
    let pages = [];
    for(let i=0; i<props.pager.getNumberOfPages(); ++i) {
      pages.push(
        <li key={i} className={isActive(i)} onClick={() => jumpToPage(i)}><a href="#">{i+1}</a></li>
      );
    }
    return pages;
  };
  
  return (
    <div id="pagination">
      <p>Showing {props.pager.getCurrentPageBeginning() + 1} to {props.pager.getCurrentPageEnd()} of {props.pager.getDataLength()} orders</p>
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className={isPreviousDisabled()} onClick={() => previousPage()}>
            <a href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          { setPages() }
          <li className={isNextDisabled()} onClick={() => nextPage()}>
            <a href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

TablePagination.propTypes = {
  pager: React.PropTypes.object.isRequired
};

export default TablePagination;