import appDispatcher from '../dispatcher/appDispatcher';
import { storeService} from '../services/storeSvc';
import { ActionTypes } from '../constants/actionTypes';

let StoreAction = {
  verifyStore: () => {
    storeService.verifyStore()
      .then(() => appDispatcher.dispatch(ActionTypes.VERIFY_STORE_SUCCESS))
      .catch(err => appDispatcher.dispatch(ActionTypes.VERIFY_STORE_FAILED, err));
  }
};

export { 
  StoreAction
};