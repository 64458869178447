import appDispatcher from '../dispatcher/appDispatcher';
import { ActionTypes } from '../constants/actionTypes';
import { printerService } from '../services/printerSvc';
 
let PrinterAction = {
  
  getPrinters: () => {
    printerService.getPrinters()
      .then(printers => appDispatcher.dispatch(ActionTypes.GOT_PRINTERS, printers))
      .catch(err => appDispatcher.dispatch(ActionTypes.CANT_GET_PRINTERS, err));
  },
  
  togglePrinting: (printer, store, isPrinting) => {
    if(isPrinting != null && typeof isPrinting === 'boolean') {
      if(isPrinting === true) {
        appDispatcher.dispatch(ActionTypes.STOP_PRINTING);
      } else {
        if(printer.name) {
          appDispatcher.dispatch(ActionTypes.START_PRINTING, {printer: printer, store: store});
        }
      }
    }
  },
  
  selectStore: store => appDispatcher.dispatch(ActionTypes.SELECT_STORE, store),
  
  printOrders: (printer, store) => {
    printerService.printNewOrders(printer, store)
      .then(data => appDispatcher.dispatch(ActionTypes.GOT_ORDERS, data))
      .catch(err => appDispatcher.dispatch(ActionTypes.CANT_GET_ORDERS, err));
  },
  
  updateStore: store => appDispatcher.dispatch(ActionTypes.UPDATE_PRINT_FILTER, store),
  
  printOrder: order => appDispatcher.dispatch(ActionTypes.PRINT_ORDER, order)

};

export {
  PrinterAction
};