import React from 'react';

const TogglePrinting = props => {
  const togglePrinting = () => {
    props.toggle(props.isPrinting);
  };
  
  return (
    <div id="toggle-printing">
      <input type="checkbox" id="toggle-printing" className="toggle toggle-slide" checked={props.isPrinting} readOnly />
      <label htmlFor="toggle-printing" className="toggle-btn" data-toggle-off="Paused" data-toggle-on="Printing" onClick={togglePrinting} />
    </div>
  );
};

TogglePrinting.propTypes = {
  isPrinting: React.PropTypes.bool.isRequired,
  toggle: React.PropTypes.func.isRequired
};

export default TogglePrinting;