import React from 'react';
import InventoryItem from './InventoryItem';
import { InventoryAction } from '../actions/inventoryAction';

const StoreInventory = ({locationKey, location, updateInventory, notify}) => {
  
  const collapseSection = () => {
    return (!location.openSection) ? 'hide' : '';
  };
  
  const toggleOpenSection = () => {
    if(location.openSection) {
      InventoryAction.closeSection(location.name);
    } else {
      InventoryAction.openSection(location.name);
    }
  };
  
  const toggleItemAvailability = item => InventoryAction.updateItemAvailability(location, item);
  
  const updateStoreInventory = () => updateInventory(location.name);
  
  // On update success / error
  (() => {
    if(notify === 2 || notify === 3) {
      $(`#inventoryModal-${locationKey}`).modal('hide');
      setTimeout(() => InventoryAction.setSaveButtonToPending(), 2500);
    }
  })();
  
  return (
    <li>
      <div className="location-header" onClick={() => toggleOpenSection()}>
        <h3 className="location-name">{location.name}</h3>
        <a className="toggle-accordion"><i className="fa fa-angle-down" /></a>
      </div>
      
      <div className={`${collapseSection()}`}>
        <ul className="inventory-by-location">
          { location.inventory
            .sort((a, b) => {
              if(a.title < b.title) return -1;
              if(a.title > b.title) return 1;
              return 0;
            })
            .map((item, idx) => <InventoryItem key={idx} locationKey={locationKey} item={item} toggleItem={toggleItemAvailability}/>) }
        </ul>
        
        <div id={`inventory-control-${locationKey}`} className="btn-notification">
          <button 
            className="btn save-inventory" 
            data-toggle="modal" 
            data-target={`#inventoryModal-${locationKey}`}>
              Save
          </button>
          <p className={`notification success ${(notify !== 2) ? 'hide': ''}`}>Updated Inventory!</p>
          <p className={`notification error ${(notify !== 3) ? 'hide': ''}`}>Something went wrong, please try again later!</p>
        </div>
      </div>
      
      <div className="modal fade" id={`inventoryModal-${locationKey}`} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id={`inventoryModal-${locationKey}-label`}>{location.name}</h4>
            </div>
            <div className="modal-body">
              <ul className="modal-inventory-list">
                {
                  location.inventory
                    .sort((a, b) => b.available - a.available)
                    .map((item, idx) => {
                    return (
                      <li key={idx} >
                        <span className="inventory-item-name">{item.title}</span>
                        <span className={`inventory-item-available ${(!item.available) ? 'unavailable' : ''}`}>
                          {
                            (item.available) ? 'Available' : 'Out of Stock'
                          }
                        </span>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
              <button 
                type="button" 
                className="btn btn-primary" 
                onClick={() => updateStoreInventory()} 
                disabled={(notify !== 0) ? 'disabled' : ''}
              >
                <span className={(notify !== 0) ? 'hide' : ''}>Save</span>
                <i className={`fa fa-spin fa-circle-o-notch ${(notify === 0) ? 'hide' : ''}`} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
    
  );
};

StoreInventory.propTypes = {
  locationKey: React.PropTypes.number.isRequired,
  location: React.PropTypes.shape({
    openSection: React.PropTypes.bool.isRequired,
    name: React.PropTypes.string.isRequired,
    inventory: React.PropTypes.array.isRequired
  }).isRequired,
  updateInventory: React.PropTypes.func.isRequired,
  notify: React.PropTypes.number.isRequired
};

export default StoreInventory;